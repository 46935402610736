import {
  CARESEEKER_TYPE_EMERGENCY,
  CARESEEKER_TYPE_HOSPITAL,
  CARESEEKER_TYPE_PROVIDER_SEARCH,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SOLUTION_HELP_AT_HOME,
  SOLUTION_MOBILE_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
} from "core/consts";
import { PatientWhitelistDefinition } from "core/types";

export const shortTermCareWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [SOLUTION_SHORT_TERM_STATIC_CARE, SOLUTION_STATIC_CARE],
  },
];

export const healthInsuranceWhitelist: PatientWhitelistDefinition = [
  {
    country: "FR",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
];

export const insuranceWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_REHABILITATION,
  },
  {
    country: "DE",
    search_type: SEARCH_TYPE_MEDICAL_SUPPLIES,
  },
];

export const socialHelpRecipientBoolWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [
      SOLUTION_MOBILE_CARE,
      SOLUTION_SHORT_TERM_STATIC_CARE,
      SOLUTION_STATIC_CARE,
    ],
  },
];
export const reliefServiceBoolWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
  },
];

export const reimbursment_for_help_at_homeWhitelist: PatientWhitelistDefinition =
  [
    {
      country: "DE",
      search_type: SEARCH_TYPE_CARE,
      solutions: [SOLUTION_HELP_AT_HOME, SOLUTION_MOBILE_CARE],
    },
  ];

export const reimbursment_securedWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    search_type: SEARCH_TYPE_CARE,
    solutions: [SOLUTION_MOBILE_CARE],
  },
];

export const carelevelWhitelist: PatientWhitelistDefinition = [
  { country: "DE" },
];

export const grantsWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_HOSPITAL,
  },
  {
    country: "FR",
    sender_type: CARESEEKER_TYPE_EMERGENCY,
  },
];

export const medicalCardWhitelist: PatientWhitelistDefinition = [
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_HOSPITAL,
  },
  {
    country: "DE",
    sender_type: CARESEEKER_TYPE_PROVIDER_SEARCH,
  },
  {
    country: "FR",
    sender_type: CARESEEKER_TYPE_EMERGENCY,
  },
];
